import { Injectable } from '@angular/core';
import { WebPubSubClient, WebPubSubClientCredential } from '@azure/web-pubsub-client';
import { HttpClient } from '@angular/common/http';
import { constant } from '../constants/app.constants';
import { environment } from '../../environments/environment'; 
import { Subject } from 'rxjs';
import { IdentifierTypeEnum, ParticipantTypeEnum } from './../modules/SecurePatientCommunication/models/chat.enum';

@Injectable({
  providedIn: 'root',
})
export class WebPubSubService {
  private client!: WebPubSubClient;
  public isConnected = false;
  private newMessageSubject = new Subject<any>();
  private newMessageReceived = new Subject<any>();
  newMessage$ = this.newMessageSubject.asObservable();
  newMessageReceived$ = this.newMessageReceived.asObservable();
  pharmacyInfo:any;
  pharmacyName:any;
  patMobileNo:any;
  constructor(private http: HttpClient) {
    this.pharmacyInfo = JSON.parse(localStorage.getItem('PharmacyInfo') as string);
    if(this.pharmacyInfo){
        this.pharmacyName = this.pharmacyInfo?.name;
        this.patMobileNo = this.pharmacyInfo?.patientMobileNo;
    } 
  }

  // Initialize WebSocket connection for patient
  async initializePatientWebSocket(patientId: string) {
    try {
        const apiUrl = `${environment.Negotiate_apiBaseUrl}/${constant.NEGOTIAGE}?userid=${patientId}`;
        const response: any = await this.http.get(apiUrl).toPromise(); 

        const clientAccessUrl = response?.url;
        if (!clientAccessUrl) {
            throw new Error('clientAccessUrl is undefined');
        }

        this.client = new WebPubSubClient({
            getClientAccessUrl: clientAccessUrl,
          } as WebPubSubClientCredential);

        this.client.on("connected", (e) => {
            console.log(`Connected: ${e.connectionId}.`);
        }); 

        // Listen for messages from pharmacy 
        this.initializeWebSocket();

        await this.client.start();
        this.isConnected = true;
        console.log('WebPubSub WebSocket connection established');
 
    } catch (error) {
        console.error('Error initializing WebSocket for pharmacist:', error);
    }
  }

  private initializeWebSocket() {
    if (this.client) {
        this.client.on("server-message", (e) => {
            if(e && e?.message?.data){
                const resMSG = e?.message?.data || '';
                const fromPat = JSON.parse(resMSG as any);
                console.log("Received message from Pharmacy:", fromPat);
                this.newMessageSubject.next(fromPat);
            }
        });
    } else {
        console.error("WebSocket client is not initialized");
    }
  }

  mapMessage(message: any) { 
     return {
        message: message
     }; 
  }
  isWebSocketConnected(): boolean {
        return this.isConnected;
  }
  // Send message to a pharmacist
  async sendMessageToPharmacist(patientId: string, message: string, patientName:string) {
        if (!this.isWebSocketConnected()) {
            throw new Error('WebSocket client is not initialized or connected');
        }

        try {
            const [patientID, npiId] = patientId.split('_');
            // Send the message event to the server 
             await this.client.sendEvent('message', {
                "messageText": message,
                    "sender": {
                        "applicationId": 1,
                        "participantType": ParticipantTypeEnum.PATIENT,
                        "applicationDescription": "string",
                        "identifier": patientID,
                        "identifierType": IdentifierTypeEnum.PATIENT,
                        "userName": patientID
                    },
                    "recipient": {
                        "participantType": ParticipantTypeEnum.PHARMACY,
                        "recipientMobileNumber": "9033927968",
                        "Identifier": npiId,
                        "IdentifierType": IdentifierTypeEnum.NPI,
                        "userName": ""
                    }
                }, 'json');
                
            console.log(`Message sent to pharmacist ${patientId}: ${message}`);
        } catch (error) {
            console.error('Error sending message to pharmacist:', error);
        }
    } 
}
